import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTheme} from "@mui/material";

export default function SessionDialog({open, handleClose}) {

    const theme = useTheme();

    return (
        <div>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{color: theme.palette.background.main}}>
                    {"La sessione è cambiata"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{color: theme.palette.background.main}}>
                        La sessione attuale dell'evento è cambiata. Puoi sempre inviare frasi per una sessione precedente cliccando sul nome della sessione.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus  sx={{color: theme.palette.background.main}}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
