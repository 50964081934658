import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {Box, Button, IconButton} from "@mui/material";
import QRCode from "react-qr-code";
import {useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";

export const QrDialog = ({open, setOpen}) => {
    const {event} = useParams()

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogContent>
                <Box sx={{background:'white', p:3, borderRadius:'2rem'}}>
                    <QRCode
                        size={256}
                        style={{height: "auto", maxWidth: "100%", width: "100%"}}
                        value={'https://real-time-report.plesh.co/'+event}
                        viewBox={`0 0 256 256`}
                    />
                </Box>
                <Box sx={{textAlign:'center', pt:3}} onClick={() => setOpen(false)}>
                    <Button variant={'contained'}>
                        chiudi
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}