import {createTheme, responsiveFontSizes} from "@mui/material";

export const moderatoriTheme = responsiveFontSizes(createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FFF'
        },
        secondary: {
            main: '#cc0d01'
        },
        background: {
            main: '#000'
        },
        sentimentNegative: {
            main: '#c80000'
        },
        sentimentPositive: {
            main: '#6ba244'
        },
        sentimentNeutral: {
            main: '#CCC'
        }
    },
}))
