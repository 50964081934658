import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    Snackbar,
    Stack,
    TextField,
    Radio,
    Typography,
    useMediaQuery,
    useTheme, Fab
} from "@mui/material";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import * as React from "react";
import SessionDialog from "../components/sessionDialog";
import {version} from "../config";
import {API_URL} from "../config";
import {Insights} from "@mui/icons-material";
import {QrDialog} from "../components/QrDialog";

const Home = () => {
    const {event: event_id} = useParams()
    const [frase, setFrase] = useState('');
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const handleCloseSuccess = () => setOpenSuccess(false);
    const handleCloseError = () => setOpenError(false);
    const theme = useTheme();
    let {event, table} = useParams();

    const docRef = firestore.doc('events/' + event);
    const [config, configLoading, configError] = useDocument(docRef);
    const [sessions, loadingSessions, errorSessions] =
        useCollection(firestore.collection('events/' + event + '/sessions').orderBy('position'))
    const [open, setOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const [session, setSession] = useState(0)
    const [currentSession, setCurrentSession] = useState(0)

    const [openQr, setOpenQr] = useState(false);

    useEffect(() => {
        let _session = null
        if (sessions && !loadingSessions && !errorSessions && config && !configLoading && !configError) {
            _session = sessions.docs.find((s) => s.id === config.data().current_session).data().position
            if (_session !== session) {
                setOpen(true);
                let _active = sessions.docs.filter((s) => s.id === config.data().current_session)
                setCurrentSession(_active[0].data().position);
                setSession(_active[0].data().position);
                setFrase('');
            }
        }
    }, [sessions, config])

    useEffect(() => {
        if (sessions && !loadingSessions && !errorSessions) {
            getQuestions().then()
        }
    }, [session, config])

    const getQuestions = async () => {
        let _session = sessions.docs.find((s) => s.data().position === session)
        // Recupero lista domande
        await firestore.doc('events/' + event + '/sessions/' + _session.id).collection('questions').get().then((querySnapshot) => {
            let questions = [];
            querySnapshot.forEach((doc) => {
                questions.push(doc.data());
            });
            questions.sort((a, b) => a.position - b.position)
            setQuestions(questions);
        })
    }

    const md = useMediaQuery(theme.breakpoints.up('md'))

    const handleChangeSession = (number) => {
        setSession(number);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function sendData() {
        let _session = sessions.docs.find((s) => s.data().position === session)
        let _question = questions[currentQuestion]
        setSending(true);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({sentence: frase, table: table, session: _session.id, question: currentQuestion})
        };
        fetch(API_URL + "events/" + event + "/sentences/" + _session.id, requestOptions)
            .then(response => response.json())
            .then(data => {
                setFrase('');
                setCurrentQuestion(null);
                setOpenSuccess(true)
                setSending(false)
            })
            .catch(error => {
                setOpenError(true)
                setSending(false)
            });
    }

    return <Stack sx={{
        background: theme.palette.background.main, width: '100%', minHeight: '100dvh'
    }} direction={"column"}
                  justifyContent={"center"} alignItems={"center"}>
        <QrDialog open={openQr} setOpen={setOpenQr}/>
        <Container maxWidth={'xl'}>
            <Stack direction={"column"} sx={{px: 5}}>
                <Stack container sx={{width: '100%', mt: 2, mb: 2, textAlign: 'left'}} spacing={2} alignItems={"center"}
                       direction={md ? "row" : "column"} justifyContent={"space-between"}>
                    <Stack sx={{flexShrink: 0}} direction={'row'} spacing={3} alignItems={'center'}>
                        <Box>
                            {!configLoading && !configError && config &&
                                <img src={config.data().url_logo} style={{maxWidth: '8rem', width: '100%'}}/>}
                        </Box>
                        {config && config.data() && config.data().liveDataButton && <Grid item xs={4}>
                            <Stack direction={"column"} justifyContent={"end"} alignItems={"end"}>
                                <Fab variant={"extended"} size={"medium"}
                                     onClick={() => setOpenQr(true)}
                                     color="primary">
                                    <Insights sx={{mr: 1}}/> LIVE REPORT
                                </Fab>
                            </Stack>
                        </Grid>}
                    </Stack>
                    {!configLoading && !configError && config && config.data().message.length > 0 &&
                        <Alert severity={"warning"} variant={"filled"}>
                            {config.data().message}
                        </Alert>}
                    <Box sx={{flexShrink: 0}}>
                        <Typography color={theme.palette.primary.main} textAlign={"right"} component={"p"}
                                    variant={"h4"} fontWeight={"bold"}>Tavolo {table}</Typography>
                    </Box>
                </Stack>
                <Box>
                    <Box sx={{pb: 1}}>
                        <FormControl>
                            <FormLabel>Sessione</FormLabel>
                            <Stack direction={"row"} alignItems={"start"} sx={{mt: 1}} spacing={2}>
                                {
                                    sessions && !loadingSessions && !errorSessions && sessions.docs.map((s) =>
                                        <Button
                                            variant={s.data().position === session ? 'contained' : 'outlined'}
                                            onClick={() => handleChangeSession(s.data().position)}
                                            disabled={s.data().position > currentSession}
                                        >
                                            {s.data().name}
                                        </Button>
                                    )}
                            </Stack>
                        </FormControl>
                        {sessions && !loadingSessions && !errorSessions && sessions.docs.find((s) => s.data().position === session).data().description!== '' &&
                            <Box sx={{pt: 2, pb: 1}}>
                                <FormControl>
                                    <FormLabel>Descrizione</FormLabel>
                                    <Typography variant={'h6'}>
                                        {sessions.docs.find((s) => s.data().position === session).data().description}
                                    </Typography>
                                </FormControl>
                            </Box>
                        }
                    </Box>
                    {questions.length > 0 && <Box>
                        <FormControl sx={{my:1}}>
                            <FormLabel>Domande di riferimento:</FormLabel>
                            <RadioGroup
                                name="radio-buttons-group"
                                value={currentQuestion}
                            >
                                {questions.map((question, index) => (
                                    <FormControlLabel key={index}
                                                      value={index}
                                                      control={<Radio onClick={(e) => {
                                                          if (currentQuestion === index) {
                                                              setCurrentQuestion(null)
                                                          } else {
                                                              setCurrentQuestion(index)
                                                          }
                                                      }}/>}
                                                      sx={{mt:2}}
                                                      label={question.content}/>)
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Box>}
                </Box>
                <TextField
                    id="frase"
                    label="Inserisci la frase qui"
                    multiline
                    minRows={4}
                    sx={{mt: 1}}
                    value={frase}
                    onChange={(event) => setFrase(event.target.value)}
                />

                <Box sx={{width: '100%', textAlign: 'center', mb: 0}}>
                    {sending ? <Box sx={{mt: 2, pb: '1px', pt: '1.75px'}}><CircularProgress/></Box> :
                        <Button disabled={frase.length === 0} size="large" variant={"contained"}
                                onClick={sendData}
                                sx={{mt: 3, width: '10rem'}}>
                            invia
                        </Button>}
                </Box>

                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"flex-end"}>
                    <Typography variant={"body2"}>v. {version}</Typography>
                </Stack>
            </Stack>
        </Container>

        <Snackbar open={openSuccess} autoHideDuration={4000} onClose={handleCloseSuccess}>
            <Alert elevation={6} variant={"filled"} onClose={handleCloseSuccess} severity="success"
                   sx={{width: '100%'}}>
                Frase inviata con successo
            </Alert>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={4000} onClose={handleCloseError}>
            <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error" sx={{width: '100%'}}>
                Errore durante l'invio, si prega di riprovare
            </Alert>
        </Snackbar>
        <SessionDialog open={open} handleClose={handleClose}/>
    </Stack>
}

export default Home;
