import React, {useState} from "react";
import {Box, Button, Card, CardContent, Container, Stack, TextField, Typography} from "@mui/material";
import {Link} from "react-router-dom";

export const ScegliEvento = () => {
    const [evento, setEvento] = useState('')
    return (
        <Stack sx={{width: '100vw', height: '100vh'}} justifyContent={'center'}>
            <Container maxWidth={'xs'}>
                <Card>
                    <CardContent>
                        <Stack spacing={2}>
                            <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
                                Inserisci il nome dell'evento:
                            </Typography>
                            <TextField label={'Nome evento'} onChange={(e) => setEvento(e.target.value)}/>
                            <Button component={Link} to={`/${evento}`} disabled={evento===''} variant={'contained'}>
                                Vai
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Container>
        </Stack>
    )
}